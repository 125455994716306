import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.646436, 166.934252, 2.2],
  [-11.646551, 166.934284, 2.2],
  [-11.646664, 166.934316, 2.2],
  [-11.646779, 166.934348, 2.2],
  [-11.646899, 166.934381, 2.2],
  [-11.647015, 166.934391, 2.2],
  [-11.647129, 166.934402, 2.2],
  [-11.647247, 166.934402, 2.2],
  [-11.647363, 166.934423, 2.2],
  [-11.647478, 166.934423, 2.2],
  [-11.647592, 166.934402, 2.2],
  [-11.647709, 166.934402, 2.2],
  [-11.647823, 166.934391, 2.2],
  [-11.647935, 166.934381, 2.2],
  [-11.648049, 166.934359, 2.2],
  [-11.648162, 166.934327, 2.2],
  [-11.648303, 166.934316, 2.2],
  [-11.648386, 166.934284, 2.2],
  [-11.648498, 166.934273, 2.2],
  [-11.648607, 166.934241, 2.2],
  [-11.648716, 166.934209, 2.2],
  [-11.648824, 166.934177, 2.2],
  [-11.648933, 166.934144, 2.2],
  [-11.649039, 166.934112, 2.2],
  [-11.649145, 166.934091, 2.4],
  [-11.649252, 166.934037, 2.4],
  [-11.649359, 166.934005, 2.4],
  [-11.649468, 166.933984, 2.4],
  [-11.649574, 166.933951, 2.4],
  [-11.649681, 166.933919, 2.4],
  [-11.649787, 166.933887, 2.4],
  [-11.649895, 166.933855, 2.4],
  [-11.650002, 166.933844, 2.4],
  [-11.65011, 166.933812, 2.6],
  [-11.65022, 166.933801, 2.6],
  [-11.65033, 166.93378, 2.6],
  [-11.650443, 166.93378, 2.6],
  [-11.650558, 166.93378, 2.6],
  [-11.65067, 166.933812, 2.6],
  [-11.650782, 166.933823, 2.6],
  [-11.650892, 166.933855, 2.8],
  [-11.651004, 166.933887, 2.8],
  [-11.651116, 166.933919, 2.8],
  [-11.65123, 166.933951, 2.8],
  [-11.651343, 166.933994, 2.8],
  [-11.651456, 166.934026, 2.8],
  [-11.651572, 166.934069, 2.8],
  [-11.651684, 166.934112, 2.6],
  [-11.651802, 166.934144, 2.6],
  [-11.651918, 166.934177, 2.6],
  [-11.652043, 166.934198, 2.8],
  [-11.65216, 166.934198, 2.8],
  [-11.652279, 166.934177, 2.8],
  [-11.652397, 166.934144, 3],
  [-11.652507, 166.934112, 3.2],
  [-11.652616, 166.934069, 3.2],
  [-11.652723, 166.934026, 3.4],
  [-11.652825, 166.933984, 3.6],
  [-11.652928, 166.93393, 3.6],
  [-11.653029, 166.933876, 3.8],
  [-11.653131, 166.933823, 4],
  [-11.65323, 166.933801, 4.2],
  [-11.653329, 166.933748, 4.4],
  [-11.653428, 166.933694, 4.6],
  [-11.653522, 166.93364, 4.8],
  [-11.653617, 166.933597, 5],
  [-11.653714, 166.933533, 5.2],
  [-11.653805, 166.93349, 5.6],
  [-11.653899, 166.933447, 5.8],
  [-11.653992, 166.933404, 6],
  [-11.654084, 166.933372, 6.2],
  [-11.654179, 166.933318, 6.6],
  [-11.654275, 166.933297, 6.8],
  [-11.654367, 166.933265, 7],
  [-11.654462, 166.933233, 7.4],
  [-11.654554, 166.9332, 7.6],
  [-11.654645, 166.933168, 8],
  [-11.654735, 166.933125, 8.2],
  [-11.654822, 166.933093, 8.6],
  [-11.654902, 166.933029, 9],
  [-11.654973, 166.932975, 9.2],
  [-11.655034, 166.9329, 9.6],
  [-11.655081, 166.932825, 10],
  [-11.655114, 166.932728, 10.2],
  [-11.655137, 166.932632, 10.6],
  [-11.655156, 166.932546, 11],
  [-11.655172, 166.932449, 11.4],
  [-11.655183, 166.932364, 11.6],
  [-11.655192, 166.932267, 12],
  [-11.655199, 166.932181, 12.4],
  [-11.655203, 166.932106, 12.8],
  [-11.655203, 166.932009, 13.2],
  [-11.655203, 166.931924, 13.6],
  [-11.655199, 166.931827, 14.2],
  [-11.655191, 166.931752, 14.6],
  [-11.655176, 166.931677, 15.2],
  [-11.65516, 166.931602, 16],
  [-11.65514, 166.931527, 16.6],
  [-11.65512, 166.931452, 17.2],
  [-11.655099, 166.931387, 17.8],
  [-11.655079, 166.931323, 18.4],
  [-11.65506, 166.931258, 19],
  [-11.655046, 166.931205, 19.6],
  [-11.655031, 166.93114, 20.2],
  [-11.655019, 166.931076, 20.6],
  [-11.655011, 166.931033, 21],
  [-11.655006, 166.930969, 21.6],
  [-11.655004, 166.930915, 22],
  [-11.65501, 166.930861, 22.2],
  [-11.655028, 166.930808, 22.4],
  [-11.655055, 166.930765, 22.6],
  [-11.655095, 166.930701, 22.8],
  [-11.655146, 166.930668, 22.8],
  [-11.655213, 166.930636, 22.8],
  [-11.655288, 166.930625, 22.8],
  [-11.655366, 166.930625, 22.6],
  [-11.655451, 166.930625, 22.6],
  [-11.655537, 166.930625, 22.4],
  [-11.655624, 166.930636, 22.4],
  [-11.655715, 166.930658, 22.2],
  [-11.65581, 166.930668, 22.2],
  [-11.655905, 166.930679, 22.2],
  [-11.656003, 166.930701, 22.2],
  [-11.656107, 166.930711, 22.4],
  [-11.656203, 166.930733, 22.6],
  [-11.656305, 166.930733, 22.8],
  [-11.656404, 166.930743, 23.2],
  [-11.656504, 166.930765, 23.4],
  [-11.6566, 166.930765, 23.8],
  [-11.656697, 166.930776, 24.2],
  [-11.656793, 166.930776, 24.4],
  [-11.656886, 166.930786, 24.6],
  [-11.656984, 166.930786, 25],
  [-11.657076, 166.930786, 25.2],
  [-11.65717, 166.930786, 25.2],
  [-11.657268, 166.930786, 25.4],
  [-11.657362, 166.930786, 25.6],
  [-11.657458, 166.930786, 25.8],
  [-11.657554, 166.930786, 25.8],
  [-11.65765, 166.930786, 26],
  [-11.657745, 166.930776, 26.2],
  [-11.657841, 166.930776, 26.2],
  [-11.657937, 166.930765, 26.4],
  [-11.658032, 166.930743, 26.6],
  [-11.658128, 166.930733, 26.8],
  [-11.658224, 166.930711, 26.8],
  [-11.658322, 166.930701, 26.8],
  [-11.658421, 166.930679, 27],
  [-11.658518, 166.930658, 27],
  [-11.65862, 166.930636, 27.2],
  [-11.658719, 166.930604, 27.4],
  [-11.658816, 166.930572, 27.6],
  [-11.658909, 166.930529, 28.2],
  [-11.658999, 166.930486, 28.6],
  [-11.659084, 166.930454, 29.4],
  [-11.659164, 166.930411, 30.2],
  [-11.659238, 166.930368, 31],
  [-11.659308, 166.930314, 31.8],
  [-11.659369, 166.930271, 32.8],
  [-11.659428, 166.930228, 33.6],
  [-11.659478, 166.930175, 34.4],
  [-11.659526, 166.930132, 35],
  [-11.659569, 166.9301, 35.8],
  [-11.65961, 166.930057, 36.4],
  [-11.659647, 166.930025, 37],
  [-11.659682, 166.929982, 37.4],
  [-11.659715, 166.92995, 38],
  [-11.659749, 166.929917, 38.4],
  [-11.659779, 166.929874, 39],
  [-11.659805, 166.929842, 39.4],
  [-11.659829, 166.929799, 39.8],
  [-11.65985, 166.929756, 40.2],
  [-11.659871, 166.929703, 40.6],
  [-11.659888, 166.92966, 41],
  [-11.659903, 166.929617, 41.2],
  [-11.659915, 166.929574, 41.6],
  [-11.659926, 166.92952, 42],
  [-11.659934, 166.929467, 42.2],
  [-11.659939, 166.929413, 42.4],
  [-11.659943, 166.929359, 42.8],
  [-11.659943, 166.929295, 42.8],
  [-11.659939, 166.929231, 43],
  [-11.659934, 166.929156, 43.2],
  [-11.659925, 166.929091, 43.2],
  [-11.659914, 166.929016, 43.4],
  [-11.659898, 166.928941, 43.4],
  [-11.65988, 166.928866, 43.4],
  [-11.65986, 166.928791, 43.2],
  [-11.659835, 166.928716, 43.2],
  [-11.659808, 166.928619, 43.2],
  [-11.659779, 166.928544, 43],
  [-11.659748, 166.928458, 43],
  [-11.659711, 166.928362, 42.8],
  [-11.659674, 166.928254, 42.6],
  [-11.659628, 166.928169, 42.4],
  [-11.659579, 166.928072, 42.2],
  [-11.659528, 166.927986, 42],
  [-11.659474, 166.927879, 41.6],
  [-11.659423, 166.927782, 41.4],
  [-11.659375, 166.927675, 41],
  [-11.659332, 166.927568, 40.4],
  [-11.659293, 166.92745, 40],
  [-11.659257, 166.927332, 39.2],
  [-11.659221, 166.927203, 38.6],
  [-11.659188, 166.927085, 37.8],
  [-11.659155, 166.926956, 37.2],
  [-11.659121, 166.926827, 36.4],
  [-11.659088, 166.926688, 36],
  [-11.659057, 166.926548, 35.4],
  [-11.659029, 166.926409, 35],
  [-11.659003, 166.92627, 34.6],
  [-11.658977, 166.926141, 34.2],
  [-11.658944, 166.926001, 34],
  [-11.658907, 166.925862, 33.6],
  [-11.658859, 166.925722, 33.6],
  [-11.658802, 166.925604, 33.4],
  [-11.65874, 166.925476, 33.6],
  [-11.658673, 166.925358, 33.6],
  [-11.658602, 166.92525, 33.8],
  [-11.65853, 166.925143, 33.8],
  [-11.658449, 166.925036, 34],
  [-11.658366, 166.92495, 34],
  [-11.658278, 166.924853, 34],
  [-11.658183, 166.924778, 33.8],
  [-11.658085, 166.924703, 33.8],
  [-11.657988, 166.924628, 33.8],
  [-11.65789, 166.924553, 33.6],
  [-11.657791, 166.924489, 33.6],
  [-11.657694, 166.924413, 33.8],
  [-11.657603, 166.924338, 34],
  [-11.657511, 166.924274, 34.2],
  [-11.657418, 166.924199, 34.4],
  [-11.657328, 166.924134, 34.6],
  [-11.65724, 166.924081, 34.8],
  [-11.657154, 166.924016, 35.2],
  [-11.657069, 166.923941, 35.4],
  [-11.656988, 166.923877, 35.6],
  [-11.656911, 166.923802, 35.8],
  [-11.656839, 166.923738, 36.2],
  [-11.656769, 166.923662, 36.4],
  [-11.656702, 166.923577, 36.6],
  [-11.656639, 166.923501, 37],
  [-11.656575, 166.923416, 37.2],
  [-11.656513, 166.923341, 37.4],
  [-11.656447, 166.923265, 37.6],
  [-11.656384, 166.92319, 37.8],
  [-11.656317, 166.923126, 38],
  [-11.656249, 166.923051, 38.2],
  [-11.65618, 166.922976, 38.4],
  [-11.656113, 166.922911, 38.6],
  [-11.656046, 166.922836, 38.8],
  [-11.655976, 166.922783, 39],
  [-11.655904, 166.922718, 39.2],
  [-11.65583, 166.922654, 39.2],
  [-11.65575, 166.9226, 39.4],
  [-11.655668, 166.922536, 39.4],
  [-11.65558, 166.922493, 39.4],
  [-11.655486, 166.922461, 39.2],
  [-11.655388, 166.922418, 39],
  [-11.655282, 166.922396, 38.8],
  [-11.655174, 166.922386, 38.4],
  [-11.655062, 166.922386, 38.2],
  [-11.654951, 166.922418, 37.8],
  [-11.654838, 166.922429, 37.6],
  [-11.654728, 166.922472, 37.2],
  [-11.654618, 166.922525, 37],
  [-11.654511, 166.922568, 36.8],
  [-11.654403, 166.922622, 36.4],
  [-11.654299, 166.922686, 36.2],
  [-11.654198, 166.922761, 35.8],
  [-11.654098, 166.922836, 35.6],
  [-11.654005, 166.922933, 35.4],
  [-11.653915, 166.923019, 35.2],
  [-11.653832, 166.923126, 35],
  [-11.653753, 166.923223, 34.8],
  [-11.65367, 166.923341, 34.6],
  [-11.653593, 166.923448, 34.4],
  [-11.653522, 166.923555, 34],
  [-11.65345, 166.923662, 33.6],
  [-11.65338, 166.923791, 33],
  [-11.653314, 166.923909, 32.6],
  [-11.653251, 166.924027, 32],
  [-11.653192, 166.924156, 31.2],
  [-11.653133, 166.924285, 30.6],
  [-11.653077, 166.924413, 30.2],
  [-11.653023, 166.924553, 29.8],
  [-11.652971, 166.924682, 29.4],
  [-11.652925, 166.92481, 29],
  [-11.652881, 166.92495, 28.8],
  [-11.652838, 166.925079, 28.4],
  [-11.652797, 166.925218, 28],
  [-11.652754, 166.925347, 27.6],
  [-11.652711, 166.925476, 27],
  [-11.652671, 166.925615, 26.2],
  [-11.652633, 166.925755, 25.4],
  [-11.652597, 166.925894, 24.6],
  [-11.652557, 166.926033, 23.8],
  [-11.652506, 166.926184, 23],
  [-11.65244, 166.926323, 22.2],
  [-11.652317, 166.926398, 22],
  [-11.652172, 166.926409, 22],
  [-11.652039, 166.926345, 22],
  [-11.651917, 166.92627, 22],
  [-11.651804, 166.926184, 21.8],
  [-11.651692, 166.926109, 21.6],
  [-11.651579, 166.926012, 21.4],
  [-11.65146, 166.925958, 20.8],
  [-11.651339, 166.925894, 20.2],
  [-11.651214, 166.925819, 19.4],
  [-11.651085, 166.925755, 18.6],
  [-11.650951, 166.92569, 17.6],
  [-11.650813, 166.925647, 16.6],
  [-11.65067, 166.925615, 15.8],
  [-11.650521, 166.925604, 14.8],
  [-11.650369, 166.925615, 14],
  [-11.650219, 166.925647, 13],
  [-11.650076, 166.925712, 12.4],
  [-11.649947, 166.925787, 12],
  [-11.649836, 166.925894, 11.6],
  [-11.64973, 166.926012, 11.6],
  [-11.649633, 166.926141, 11.4],
  [-11.649532, 166.926248, 11.2],
  [-11.649429, 166.926345, 11],
  [-11.649326, 166.926452, 11],
  [-11.649223, 166.926548, 10.8],
  [-11.649122, 166.926656, 10.6],
  [-11.64902, 166.926742, 10.4],
  [-11.64892, 166.926838, 10.2],
  [-11.648815, 166.926935, 10],
  [-11.648706, 166.92701, 9.8],
  [-11.648595, 166.927085, 9.6],
  [-11.648493, 166.927171, 9],
  [-11.648401, 166.927278, 8.4],
  [-11.648304, 166.927375, 7.6],
  [-11.648207, 166.927482, 6.8],
  [-11.648119, 166.9276, 6],
  [-11.648038, 166.927707, 5.4],
  [-11.647971, 166.927847, 4.6],
  [-11.647922, 166.927986, 4.2],
  [-11.647894, 166.928115, 3.8],
  [-11.64788, 166.928276, 3.4],
  [-11.647872, 166.928405, 3.2],
  [-11.647867, 166.928566, 3],
  [-11.647857, 166.928705, 2.8],
  [-11.647835, 166.928855, 2.6],
  [-11.6478, 166.928995, 2.4],
  [-11.647765, 166.929123, 2.4],
  [-11.647726, 166.929263, 2.4],
  [-11.647684, 166.929402, 2.4],
  [-11.647636, 166.929542, 2.6],
  [-11.647584, 166.92966, 2.6],
  [-11.647528, 166.929778, 2.6],
  [-11.647464, 166.929907, 2.6],
  [-11.647392, 166.930014, 2.6],
  [-11.647317, 166.930121, 2.4],
  [-11.647236, 166.930207, 2.4],
  [-11.647155, 166.930304, 2.4],
  [-11.647072, 166.930411, 2.2],
  [-11.64699, 166.930497, 2.2],
  [-11.646907, 166.930593, 2.2],
  [-11.646829, 166.930701, 2.2],
  [-11.646754, 166.930786, 2.2],
  [-11.646679, 166.930883, 2.2],
  [-11.646603, 166.930969, 2.2],
  [-11.646531, 166.931076, 2.2],
  [-11.646461, 166.931173, 2.2],
  [-11.64639, 166.931258, 2.2],
  [-11.646326, 166.931366, 2.2],
  [-11.646262, 166.931462, 2.2],
  [-11.6462, 166.93157, 2.2],
  [-11.646139, 166.931655, 2.2],
  [-11.64608, 166.931763, 2.2],
  [-11.646023, 166.93187, 2.2],
  [-11.645969, 166.931977, 2.2],
  [-11.64592, 166.932074, 2.2],
  [-11.645875, 166.932181, 2.2],
  [-11.645835, 166.932288, 2.2],
  [-11.645797, 166.932406, 2.2],
  [-11.645761, 166.932514, 2.2],
  [-11.645732, 166.932621, 2.2],
  [-11.645705, 166.932739, 2.2],
  [-11.645685, 166.932846, 2.2],
  [-11.645673, 166.932975, 2.2],
  [-11.645667, 166.933082, 2.2],
  [-11.645671, 166.9332, 2.2],
  [-11.645685, 166.933308, 2.2],
  [-11.645709, 166.933415, 2.2],
  [-11.64574, 166.933522, 2.2],
  [-11.64578, 166.93363, 2.2],
  [-11.64583, 166.933737, 2.2],
  [-11.645887, 166.933823, 2.2],
  [-11.645953, 166.93393, 2.2],
  [-11.646032, 166.934005, 2.2],
  ];

export default stream;
