import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.644495, 166.935665, 2.2],
  [-11.644388, 166.935626, 2.2],
  [-11.644279, 166.935596, 2.2],
  [-11.644169, 166.935581, 2.2],
  [-11.644056, 166.93558, 2.2],
  [-11.643943, 166.93559, 2.2],
  [-11.643832, 166.935608, 2.2],
  [-11.643721, 166.93563, 2.2],
  [-11.643611, 166.935654, 2.2],
  [-11.6435, 166.935676, 2.2],
  [-11.643387, 166.935687, 2.2],
  [-11.643276, 166.9357, 2.2],
  [-11.643165, 166.935702, 2.2],
  [-11.643057, 166.935665, 2.2],
  [-11.642957, 166.935616, 2.2],
  [-11.642848, 166.935615, 2.4],
  [-11.642781, 166.9357, 2.8],
  [-11.642827, 166.935791, 3.6],
  [-11.642915, 166.935847, 4.2],
  [-11.643003, 166.93589, 4.8],
  [-11.64309, 166.935927, 5.4],
  [-11.643177, 166.935956, 5.8],
  [-11.643266, 166.935974, 6.4],
  [-11.643353, 166.935985, 6.8],
  [-11.643438, 166.935993, 7.2],
  [-11.643522, 166.936001, 7.6],
  [-11.643604, 166.93601, 8],
  [-11.643683, 166.936025, 8.4],
];

export default stream;
